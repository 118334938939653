module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"uktoyauctions","defaultLang":"en-gb","path":"/preview","previews":true,"pages":[{"type":"Page","match":"/","component":"/opt/build/repo/src/templates/page.jsx"},{"type":"Page","match":"/:uid","component":"/opt/build/repo/src/templates/page.jsx"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UK Toy Auctions","short_name":"UKToyAuctions","start_url":"/","background_color":"#276749","theme_color":"#276749","display":"minimal-ui","icon":"src/images/company_logo.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2cd12a69e9a9b41b5e3c8233d1d6fb8e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-170944133-1","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
